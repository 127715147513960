import React from "react"
import Terms from "../components/Contract/T&C/Content.jsx"
import Layout from "../components/Layout"


import { Helmet } from "react-helmet"
export default function TermsPage() {
 

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/terms-of-use" />
      </Helmet>
      <Terms />
    
    </Layout>
  )
}
